import React, { useEffect } from 'react'
import '../styles/Information.css'

export default function Information(props) {

    const openCity = (evt, cityName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    useEffect(() => {
        document.getElementById("defaultOpen").click();
    }, []);

    for (var i = 0; i < props.data.products.length; i++) {
        let list = document.getElementById("productList");
        let li = document.createElement("li");
        li.setAttribute("class", "list-group-item list-group-item-action list-group-item-primary");
        li.innerHTML = `<div class="row"><div class="col-md-8"><b>Ürün Adı:</b> ${props.data.products[i].product}</div><div class="col-md-4"><b>Adet:</b> ${props.data.products[i].quantity}</div> </div>`;
        list.appendChild(li);
    }

    return (
        <div className='container mt-3'>

            <div className="tab">
                <button className="tablinks" style={{ color: "black" }} onClick={(event) => openCity(event, 'TruckInformation')} id="defaultOpen" ><i className="fas fa-truck"></i><b> Lojistik/ Araç Bilgi</b></button>
                <button className="tablinks" style={{ color: "black" }} onClick={(event) => openCity(event, 'ProductInformation')}><i className="fas fa-faucet"></i><b> Ürün Bilgi</b></button>
            </div>

            <div id="TruckInformation" className="tabcontent mb-5 p-4">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="card text-black mb-3" style={{ backgroundColor: "#FFE0B2" }}>
                            <div className="card-header"><h5>Bayi Adı</h5></div>
                            <div className="card-body">
                                <p className="card-text text-center">{props.data.dealerName}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card text-black mb-3" style={{ backgroundColor: "#FFF9C4" }}>
                            <div className="card-header"><h5>Bayi Adresi</h5></div>
                            <div className="card-body">
                                <p className="card-text text-center">{props.data.dealerAddress} / {props.data.dealerCity}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="card text-black mb-3" style={{ backgroundColor: "#C5CAE9" }}>
                            <div className="card-header"><h5>Plaka</h5></div>
                            <div className="card-body">
                                <p className="card-text text-center">{props.data.vehicleName}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card text-black mb-3" style={{ backgroundColor: "#BBDEFB" }}>
                            <div className="card-header"><h5>Şoför / Telefon</h5></div>
                            <div className="card-body">
                                <p className="card-text text-center">{props.data.driverName} / <a href={`tel:${props.data.driverGsm}`} style={{ color: "black", textDecoration: "none", fontWeight: "bold" }}>{props.data.driverGsm} </a> <i className="fas fa-phone"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card text-black mb-3" style={{ backgroundColor: "#F0F4C3" }}>
                            <div className="card-header"><h5>Son Konum</h5></div>
                            <div className="card-body">
                                <p className="card-text text-center">{props.data.isGpsFixed === 1 ? props.data.locationAddress : "Araç konumu belirlenemedi !"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ProductInformation" className="tabcontent mb-5 p-4">
                <div className="card" style={{ backgroundColor: "#bacbe6" }}>
                    <div className="card-header"><h5>Teslim Edilecek Ürünler</h5></div>
                    <div className="card-body" style={{ backgroundColor: "#cfe2ff" }}>
                        <ul id="productList" className="list-group list-group-flush">

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
