import React from 'react'

export default function Stepper() {
    return (
        <div className="container" >
            <div style={{ textAlign: "center", fontSize: "25px", color: "#eb6123" }}> Sevkiyatınız Dağıtıma Çıkarıldı </div>
            <div style={{ width: "100%" }}>
                <div style={{ display: "table", width: "100%" }}>
                    <div style={{ width: "33.3333%", display: "table-cell", position: "relative", paddingTop: "24px" }}>
                        <div style={{ width: "32px", height: "32px", margin: "0px auto", backgroundColor: "#eb6123", borderRadius: "50%", textAlign: "center", padding: "1px", fontSize: " 14px", color: "rgb(255, 255, 255)", display: "block" }}>
                            <p style={{ lineHeight: "30px", color: "rgb(255, 255, 255)" }}><i className="fas fa-check"></i></p></div>
                        <p style={{ marginTop: "8px", fontSize: "14px", fontWeight: "300", textAlign: "center", display: "block" }}><b>Hazırlanıyor</b></p>
                        <div style={{ position: "absolute", top: "40px", height: "1px", borderTop: "1px solid #eb6123", right: "0px", left: "50%", marginLeft: "10px" }}></div></div>
                    <div style={{ width: "33.3333%", display: "table-cell", position: "relative", paddingTop: "24px" }}>
                        <div style={{ width: "32px", height: "32px", margin: " 0px auto", backgroundColor: "#eb6123", borderRadius: "50%", textAlign: "center", padding: "1px", fontSize: "14px", color: "rgb(255, 255, 255)", display: "block" }}>
                            <p style={{ lineHeight: "30px", color: "rgb(255, 255, 255)" }}><i className="fas fa-check"></i></p></div>
                        <p style={{ marginTop: "8px", fontSize: "14px", fontWeight: "300", textAlign: "center", display: "block" }}><b>Dağıtıma Çıktı</b></p>
                        <div style={{ position: "absolute", top: "40px", height: "1px", borderTop: "1px solid rgb(224, 224, 224)", right: "0px", left: "50%", marginLeft: "16px" }}></div>
                        <div style={{ position: "absolute", top: "40px", height: "1px", borderTop: "1px solid #eb6123", right: "50%", left: "0px", marginRight: "10px" }}></div></div>
                    <div style={{ width: "33.3333%", display: "table-cell", position: "relative", paddingTop: "24px" }}>
                        <div style={{ width: "32px", height: "32px", margin: " 0px auto", backgroundColor: "rgb(224, 224, 224)", borderRadius: "50%", textAlign: "center", padding: "1px", fontSize: "14px", color: "rgb(255, 255, 255)", display: "block" }}>
                            <p style={{ lineHeight: "30px", color: "rgb(255, 255, 255)" }}><i className="fas fa-check"></i></p></div>
                        <p style={{ marginTop: "8px", fontSize: "14px", fontWeight: "300", textAlign: "center", display: "block" }}>Teslim Edildi</p>
                        <div style={{ position: "absolute", top: "40px", height: "1px", borderTop: "1px solid rgb(224, 224, 224)", right: "50%", left: "0px", marginRight: "10px" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
