import React, { useState } from 'react';
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import truckIcon from '../images/truck-marker.png';
import storeIcon from '../images/store-marker.png';
import Panel from '../components/Panel';
import MapLoading from '../components/MapLoading';

export default function Map(props) {
    const [dealerInfo, setDealerInfo] = useState(true);
    const [truckInfo, setTruckInfo] = useState(true);

    const defaultCenter = {
        lat: (props.data.latitude + props.data.dealerCoordinatLat) / 2,
        lng: (props.data.longitude + props.data.dealerCoordinatLng) / 2
    };

    const MyMapComponent = withScriptjs(withGoogleMap(() =>
        <GoogleMap
            defaultZoom={8}
            // defaultCenter={defaultCenter}
            defaultCenter={{lat: props.data.latitude, lng: props.data.longitude }}
            >

            {/* <Marker position={{ lat: props.data.dealerCoordinatLat, lng: props.data.dealerCoordinatLng }} onClick={() => { setDealerInfo(true) }} icon={storeIcon}>
                {dealerInfo &&
                    <InfoWindow>
                        <div className="p-2">
                            <h5>Teslimat Adresi</h5>
                            <strong>Bayi : </strong>{props.data.dealerName} <br /><br />
                            {props.data.dealerAddress} / {props.data.dealerCity}
                        </div>
                    </InfoWindow>}
            </Marker> */}

            <Marker position={{ lat: props.data.latitude, lng: props.data.longitude }} onClick={() => { setTruckInfo(true) }} icon={truckIcon} >
                {truckInfo &&
                    <InfoWindow
                    //onCloseClick={() => { setTruckInfo(false) }}
                    >
                        <div className="p-2">
                            <h5>Araç Konumu</h5>
                            {props.data.locationAddress}<br /><br />
                            <strong>Plaka : </strong>{props.data.vehicleName}
                        </div>
                    </InfoWindow>}
            </Marker>

        </GoogleMap>
    ))

    return (
        <div className="container">

            {/* {props.data.isGpsFixed === 1 && props.data.gpsDateStatus === 1 ? */}
            {props.data.isGpsFixed === 1 ?
                < MyMapComponent
                    isMarkerShown
                    googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `600px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                /> :
                <MapLoading />
            }
            <Panel data={props.data} />
            <hr className="mt-4" size="4" />
        </div>
    )
}
