import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Header from '../components/Header'
import Information from '../components/Information'
import Stepper from '../components/Stepper'
import Map from '../components/Map'
import Footer from '../components/Footer'
import Error from '../pages/Error'
import swal from 'sweetalert'

export default function Home() {
    const { search } = useLocation();
    const value = search.substring(3, search.length).replace(/%3D/g, "=");

    const [statusCode, setStatusCode] = useState("");
    const [vehicleInformation, setVehicleInformation] = useState({
        vehicleName: "",
        latitude: "",
        longitude: "",
        driverName: "",
        driverGsm: "",
        dealerCode: "",
        dealerName: "",
        dealerCity: "",
        locationAddress: "",
        dealerAddress: "",
        dealerCoordinatLat: "",
        dealerCoordinatLng: "",
        isGpsFixed: 0,
        gpsDateStatus: 0,
        products: []
    });

    useEffect(() => {
        axios({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_AUTH
            },
            method: 'POST',
            url: `${process.env.REACT_APP_URL}/GetVehicleInformationByToken`,
            data: {
                token: value
            }
        }).then(response => {

            const info = response.data.VehicleInformation
            let productArray = [];

            for (let i = 0; i < info.products.length; i++) {
                try {
                    productArray = [...productArray, ...JSON.parse(info.products[i].products)]
                } catch {
                    console.log("uncaught product", info.products[i].products);
                }
            }

            setStatusCode(response.data.StatusCode);
            if (response.data.StatusCode === "200") {
                setVehicleInformation({
                    vehicleName: info.vehicleName,
                    latitude: info.latitude,
                    longitude: info.longitude,
                    driverName: info.driverName,
                    driverGsm: info.driverGsm,
                    dealerCode: info.dealerCode,
                    dealerName: info.dealerName,
                    dealerCity: info.dealerCity,
                    locationAddress: info.locationAddress,
                    dealerAddress: info.dealerAddress,
                    dealerCoordinatLat: info.dealerCoordinatLat,
                    dealerCoordinatLng: info.dealerCoordinatLng,
                    isGpsFixed: info.isGpsFixed,
                    gpsDateStatus: info.gpsDateStatus,
                    products: productArray
                });
            }

        }).catch((err) => {
            swal(err.toString(), {
                button: false,
            });
        });

    }, [value])

    if (statusCode === "200") {
        document.body.style.backgroundColor = "white";
    } else {
        document.body.style.backgroundColor = "#57938d";
    }

    return (
        <div style={{ height: '100vh', overflowY: 'scroll' }}>
            {statusCode === "200" ?
                <>
                    <Header />
                    <Stepper />
                    <Information data={vehicleInformation} />
                    <Map data={vehicleInformation} />
                    <Footer />
                </>
                :
                <Error data={statusCode} />
            }
        </div>
    )
}
